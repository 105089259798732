import React from "react";
import Navbar from "./components/Navbar";
import { Routes, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import About from "./pages/About";
import Services from './pages/Services';
import Contact from "./pages/Contact";
import Service from "./pages/service";

function App() {

  return (
    <div className="min-h-screen bg-white">
      
    <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about-us" element={<About />}></Route>
          <Route path="/services" element={<Services />}></Route>
          <Route path="/contact-us" element={<Contact />}></Route>
          <Route path="/service/:id" element={<Service />}></Route>
          </Routes>
        <Footer />
          </BrowserRouter>
      </div>
  );
}

export default App;
