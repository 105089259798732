import React from 'react'
import Carousel from 'react-material-ui-carousel'

export default function Hero() {
  return (
    <Carousel
    animation="slide"
    interval={7000}
    swipe={true}
    indicatorContainerProps={{
      className: "sr-only",
      style: {
        display: "none", // 5
      },
    }}>
      {
        [
          {img: require("../img/bg.jpg")},
          {img: require("../img/bg1.jpg")},
          {img: require("../img/bg2.jpg")},
        ].map((hero, i)=>(
          <div className='bg-no-repeat bg-cover bg-center max-h-[85vh] h-[85vh]' key={i} style={{
            backgroundImage: `url(${hero.img})`
          }}>
            <div className="flex flex-col items-start justify-center w-full h-full px-4 py-24 mx-auto sm:py-32 sm:px-6 lg:px-8 bg-[#1d1d1d]/40 ">
            <h1
              className="mb-6 text-4xl font-extrabold tracking-tight text-left text-white md:text-5xl lg:text-6xl sspro"
            >
              WELCOME TO
              <br />
              EAGLE VIEW <br className="md:sr-only" /> TECHNOLOGY
            </h1>
            </div>
    </div>
      ))
      }
      </Carousel>
  )
}
