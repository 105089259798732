export const ServiceData = [
    {
      id:1,
      content: [
        {
          img: require("../img/i4.jpg"),
          title: "Discovery Series - GSM/GPRS/CATM1 - Low Cost GPS Trackers",
          content: [ 'Quad band GSM/GPRS with Class 10 or Class 12 type', 'Multi-GNSS engine for GPS and GLONASS', 'Operating Voltage Range: +8V to +33V', 'Reverse Polarity Protection', 'Built in Resettable fuse', 'Internal GPS Antenna', 'Internal GPRS Antenna', 'Micro SIM holder External Battery removal detection', 'External Battery voltage monitoring', 'Internal battery voltage monitoring', 'Internal minimum 1hour backup when external power from the vehicle battery is removed', 'Status LEDs: Power, User, GSM and GPS', 'Operating temperature: -20 to +70 degree Celsius', 'A-GPS', '1x Digital input that shall be TVS protected(dedicated for Ignition)', '2x TVS protected digital input that can be configured as either digital or analog input', '1x open collector/drain output with 200mA with fuse protected' ],
          list: [
          ]
        },
        {
          img: require("../img/i3.jpg"),
          title: "Compass Series – AIS 140 IRNSS Fleet Management Devices",
          content: [ 'Quad Band GSM - 850/900/1800/1900 MHz', 'GNSS and IRNSS Downlink with 1', '5 meters Accuracy', 'Superior FOTA(Firmware Over The Air) Capability for Updating the Device Firmware remotely', 'Two way GPRS Communication for controlling the device through Transight Portal', '4 Digital Inputs and 2 Digital Outputs | 2 Configurable Digital Input-Outputs', '1-wire Protocol', 'Serial - RS485 Communication', '2 Analogue Inputs', 'Accelerometer and Gyro sensor', 'Waterproof with IP65 grade protection', 'Embedded SIM with Dual Operator Capability', 'Variable DC Power input Range from 8 – 36 Vdc', 'All antennas inbuilt (Tamper Proof)' ],
          list: [],
        },
        {
          img: null,
          title: "Optional Features",
          content: [ 'e-SIM option', 'Additional', '1x Digital output option', '1x 1- wire interface', '1x RS232 interface'],
        },
      ]
    },
    {
      id:2,
      content: [
        {
          img: require("../img/sps.jpeg"),
          title: "Solar Power Solution / Energy Solution",
          content: [`If power security is the pressing issue, you should perhaps invest in a battery back-up solution (adding solar panels later on) or opt for a Hybrid Solution which will enable you to save on your electricity bill and allow you to operate normally during load shedding or during unexpected power cuts.`,
          
          `We have cost effective solar solution for home and business owners to integrate with the utility grid while providing power during utiltiy blackout's. Dual function inverters work both as an off-grid and grid-tied inverter in one power center, allowing battery storage for use when the grid goes down, and in the case of SolarEdge also self-consuming power in the evening instead of pulling from the grid during high consumption hours during the evening hours. These inverters are typically used where there is an unreliable grid that goes down for extended periods of time or in situations where even a short grid blackout is intolerable
          .`],
          list: [],
        },
      ]
    },
    {
      id:3,
      content: [
        {
          img: require("../img/spm.png"),
          title: "Water Pump Solution / Power Solutions and Surveilance",
          content: [`Solar Pumping Machine`,
  
          `With the solar pump installed, you are getting sufficient water with lower monthly bills than we spent on buying petrol or diesel to run the generator. The solar pumping machine that is used to pump the water for different applications using solar energy. We have different range of solar pumping system, solar water pump, and solar submersible pump.`,
        `You can’t resist good technology that offers you cheaper costs for what you need.`
        ],
          list: [],
        },
      ]
    },
]