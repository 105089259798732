import React from "react";
import PageHeader from "./PageHeader";

export default function Portfolio() {
  return (
      <div className="flex flex-col items-center pt-[100px] lg:px-[135px] px-6 gap-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[30px]">
          {
            [
              {img: require("../img/i1.jpg"), col: "lg:col-span-3"},
              {img: require("../img/shakti_visit.png"), col: "col-span-1"},
              {img: require("../img/shakti_visit2.png"), col: "col-span-2"},
              {img: require("../img/i26.jpg"), col: "col-span-1"},
              {img: require("../img/i93.jpg"), col: "col-span-1"},
            ].map((port, i)=>(
              <img src={port.img} className={`${port.col} rounded-[10px] h-[400px] w-full object-cover object-center`} alt="" />
            ))
          }
        </div>
      </div>
  );
}
