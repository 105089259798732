import React from 'react'

export default function HomeAbout({img, title, section, details: Details}: any) {
  return (
    <div className='py-[100px] lg:px-[150px] px-10 flex lg:flex-row flex-col gap-[30px] '>
        <div className="bg-no-repeat bg-cover bg-center lg:min-w-[555px] min-h-[398px] w-full rounded-lg shadow" style={{
            backgroundImage: `url(${img})`
        }}></div>
        <div className="flex flex-col">
            <div className="text-center lg:text-start">
            <div className="text-[#FF3E54] font-bold text-2xl">\ {section} \</div>
            <div className="text-[#0E1F51] font-bold text-5xl text-[40px] mt-[10px] mb-[30px]">{title}</div>
            </div>
            <div className="font-medium text-base">
            <Details />
            </div>
        </div>
    </div>
  )
}
