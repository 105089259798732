import React from 'react'

export default function HomeCTA() {
  return (
    <div className='py-[100px] lg:px-60 px-6 bg-white'>
        <div className="bg-no-repeat w-full bg-cover bg-center rounded-[10px]" style={{
            backgroundImage: `url(${require('../img/cta.png')})`
        }}>
            <div className="h-full w-full bg-[#0E1F51]/70  py-[60px] lg:px-[137px] rounded-[10px] flex flex-col justify-center items-center text-white gap-[30px] ">
                <div className="font-bold lg:text-[32px] lg:leading-[50px] text-2xl text-center">
                    To get top notch IT and Security services or solutions
                </div>
                <div className="flex items-center">
                    <a href='mailto:Info@eagleviewltd.com' className="py-[9px] px-[18px] bg-[#FF3E54] rounded-[5px]">Get In Touch</a>
                </div>
            </div>
        </div>
    </div>
  )
}
