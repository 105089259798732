import React from "react";
import { SlEnergy, SlPower, SlCamera } from "react-icons/sl";
import { FaPumpSoap } from "react-icons/fa";
import { ServiceData } from "./ServiceData";
import { Link } from "react-router-dom";

export default function HomeService() {
  return (
    <div className="flex flex-col items-center pt-[100px] lg:px-[150px] px-6 gap-10">
        <div className="text-center">
        <div className="text-[#FF3E54] font-bold text-2xl">\ Services \</div>
            <div className="text-[#0E1F51] font-bold text-5xl text-[40px] mt-[10px] mb-[30px]">Our Expertise</div>
        </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-[30px]">
        {[
            {icon: SlEnergy, title: "Energy Solutions", detail: "At EagleView we offer ways of producing energy to provide the heat, light, etc. that businesses and consumers need"},
            {icon: SlPower, title: "Power Solutions", detail: "We offer performance management, optimization, and modeling for the electric-power industry by unlocking the value of digital and advanced analytics"},
            {icon: SlCamera, title: "Surveillance Systems", detail: "We offer a system for monitoring environmental quality in order to detect areas of pollution concentration in time for remedial measures."},
            {icon: FaPumpSoap, title: "Water Pumps", detail: "Our water pumps solution helps increase the pressure of water in order to move it from one point to another."},
        ].map((serve, i) => (
          <div className="p-[30px] bg-[#f7f7f7] flex flex-col gap-5 items-start rounded-[10px] shadow transform transition-all hover:scale-105 duration-500">
            <div className="bg-white p-[15px] w-20 h-20 rounded-[10px]">
              <serve.icon className="w-[50px] h-[50px] text-[#FF3E54]" />
            </div>
            <div className=" font-extrabold text-xl text-[#0E1F51]">
              {serve.title}
            </div>
            <div className="flex h-[6px] gap-[10px] w-[90px]">
                <div className="w-[60px] rounded-[6px] h-full bg-[#FF3E54]"></div>
                <div className="w-5 rounded-[6px] h-full bg-[#FF3E54]"></div>
            </div>
            <div className="font-medium text-base">
                {serve.detail}
            </div>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-[30px]">
        {
          ServiceData.map((serve, i)=>(
            <div className="p-5 shadow-lg flex flex-col rounded-[10px]">
              <img src={serve.content[0].img} alt="" className="h-[300px] w-full rounded-[10px] object-center object-cover" />
              <div className="font-bold text-[20px] leading-[30px] pt-[50px] pb-[30px] text-[#0E1F51]">{serve.content[0].title}</div>
              <Link to={`/service/${serve.id}`} className="text-[#FF3E54] flex justify-end font-semibold text-sm">Read More</Link>
            </div>
          ))
        }
      </div>
    </div>
  );
}
// ways of producing energy to provide the heat, light, etc. that businesses and consumers need
