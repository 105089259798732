import React from 'react'
import Hero from '../components/Hero'
import Partners from '../components/Partners'
import HomeAbout from '../components/HomeAbout'
import HomeService from '../components/HomeService'
import Testimonials from '../components/Testimonials'
import HomeContact from '../components/HomeContact'
import HomeCTA from '../components/HomeCTA'
import Portfolio from '../components/Portfolio'

export default function Home() {
  return (
    <div>
        <Hero />
        <HomeAbout img={require('../img/buildingA.jpeg')} title={"An integrated turn key solutions is what we offer you"} section={"About us"} details={()=>(
            <>
            At <b className="text-[#FF3E54] ">EAGLE VIEW</b>, our experienced and certified team of professionals delivers the most complex IT solutions to your organization with world-class services. And because we have multi-platform skills, we are able to integrate different technologies as your operation expects, thus making us a «one stop shop» for your advanced technology requirements. We do not just supply technology products but we consult, implement, configure, integrate, support and maintain various technology solutions, as well as provide hands-on training to ensure your team is fully capable of managing the infrastructure. Just make the right choice and leave the rest to us
            </>
        )} />
        <Partners />
        <Portfolio />
        <HomeAbout img={require('../img/homeservice.png')} title={"We Offer IT and Security Services"} section={"What We Do"} details={()=>(
            <>
            An integrated turn key solutions is what we offer you.At <b className="text-[#FF3E54] ">EAGLE VIEW</b>, our experienced and certified team of professionals delivers the most complex IT solutions to your organization with world-class services. And because we have multi-platform skills, we are able to integrate different technologies as your operation expects, thus making us a «one stop shop» for your advanced technology requirements. We do not just supply technology products but we consult, implement, configure, integrate, support and maintain various technology solutions, as well as provide hands-on training to ensure your team is fully capable of managing the infrastructure. Just make the right choice and leave the rest to us.
            </>
        )} />
        <HomeService />
        <Testimonials />
        <HomeContact />
        <HomeCTA />
    </div>
  )
}
