import { motion } from 'framer-motion';
import React from 'react'

export default function Partners() {
    const container = {
        hidden: { y: 500 },
        show: {
          y: 0,
          transition: {
            staggerChildren: 0.2
          }
        }
      };
      
      const listItem = {
        hidden: { y: 500 },
        show: { y: 0 }
      };
      const partners = [
        require("../img/p1.jpg"),
        require("../img/p2.jpg"),
        require("../img/p3.png"),
        require("../img/p4.jpg"),
        require("../img/p5.jpg"),
      ]
      const support = [
        require("../img/s1.jpg"),
        require("../img/s2.jpg"),
        require("../img/s3.jpg"),
      ]
  return (
    <>
    <div className="bg-white">
        <div className="px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
          <p className="text-base font-extrabold tracking-wider text-center text-gray-600 uppercase sspro">
            Trusted by businesses across the continent
          </p>
          <motion.div variants={container} initial="hidden" animate="show" className="mt-6 grid grid-cols-2 md:grid-cols-3 lg:mt-8 gap-[30px]">
            {
              partners.map((img, index)=>(
              <motion.img
              variants={listItem} key={index}
                className="h-[60px] rounded-[10px] w-full object-center object-contain"
                src={img}
                alt="Workcation"
              />
              ))
            }
          </motion.div>
          <motion.div variants={container} initial="hidden" animate="show" className="mt-6 grid grid-cols-2 md:grid-cols-3 lg:mt-8 gap-[30px]">
          {
              support.map((img, index)=>(
              <motion.img
              variants={listItem} key={index}
                className="h-[60px] rounded-[10px] w-full object-center object-contain"
                src={img}
                alt="Workcation"
              />
              ))
            }
          </motion.div>
          <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
          </div>
        </div>
      </div>
    </>
  )
}
