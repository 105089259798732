import React from 'react'
import { useParams } from 'react-router'
import { ServiceData } from '../components/ServiceData'

export default function Service() {
    const params = useParams()
    const serviceData = ServiceData.find(service=>(service.id).toString()===params.id.toString())
    const { content } = serviceData
    console.log(params.id, serviceData)
    React.useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  return (
    <div className='py-[100px] lg:px-[150px] px-6 flex flex-col'>
      <div className="flex flex-col">
        <div className="text-[#0E1F51] text-4xl md:text-[52px] md:leading-[60px] font-normal">{content[0].title}</div>
        <img src={content[0].img} className='max-h-[600px] object-center object-cover mt-20' alt="" />
        <div className="flex flex-col font-normal md:text-[22px] md:leading-33px] pt-12">
          {
            content[0].content.map((txt, i)=>(
              <div className="">{txt}</div>
            ))
          }
        </div>
      </div>
      {
        content[1] &&
        <div className="flex flex-col pt-6">
          <div className="text-[#0E1F51] text-4xl md:text-[52px] md:leading-[60px] font-normal">{content[1].title}</div>
        {content[1].img && <img src={content[1].img} className='max-h-[600px] object-center object-cover mt-20' alt="" />}
        <div className="flex flex-col font-normal md:text-[22px] md:leading-33px] pt-12">
          {
            content[1].content.map((txt, i)=>(
              <div className="">{txt}</div>
            ))
          }
        </div>
        </div>
      }
      {
        content[2] &&
        <div className="flex flex-col pt-6">
          <div className="text-[#0E1F51] text-4xl md:text-[52px] md:leading-[60px] font-normal">{content[2].title}</div>
        {content[2].img && <img src={content[2].img} className='max-h-[600px] object-center object-cover mt-20' alt="" />}
        <div className="flex flex-col font-normal md:text-[22px] md:leading-33px] pt-12">
          {
            content[2].content.map((txt, i)=>(
              <div className="">{txt}</div>
            ))
          }
        </div>
        </div>
      }
    </div>
  )
}
