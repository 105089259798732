/* eslint-disable jsx-a11y/anchor-is-valid */
import { FaLinkedinIn, FaInstagram, FaFacebookF, FaTwitter } from "react-icons/fa"
import { AiFillMail, AiFillPhone } from "react-icons/ai"
import { IoLogoWhatsapp, IoMdTimer } from "react-icons/io"
import { GoLocation } from "react-icons/go"
import { BsCalendar2Day } from "react-icons/bs"
const RedPill = () => (
    <div className="h-[5px] w-[50px] rounded-[5px] bg-[#FF3E54] mt-[20px] mb-[30px]"></div>
)
export default function Footer() {
  return (
    <div className='bottom-0 py-[100px] lg:px-[150px] px-6 bg-[#0E1F51] text-[#F7F7F7] flex flex-col'>
    <div className='grid md:grid-cols-2 xl:grid-cols-4 gap-5 '>
        <div className="flex flex-col">
            <div className="font-bold text-2xl">About Us</div>
            <RedPill />
            <div className=" text-base font-medium lg:max-w-[260px] mb-[74px]">
            A system integrator / IT solution
with exceptional abilities for engineering solutions
            </div>
            <div className="flex justify-between">
                <a href="https://www.linkedin.com/in/eagle-view-technology-limited-3992ba266" target="_blank" rel="noreferrer" className="flex items-center justify-center h-[50px] w-[50px] bg-white rounded-[10px] text-[#FF3E54]"><FaLinkedinIn className='h-5 w-5' /></a>
                <a href="https://www.instagram.com/eagleviewtechng/" target="_blank" rel="noreferrer" className="flex items-center justify-center h-[50px] w-[50px] bg-white rounded-[10px] text-[#FF3E54]" ><FaInstagram className='h-5 w-5' /></a>
                <a href="https://www.facebook.com/profile.php?id=100089945092671&mibextid=ZbWKwL" target="_blank" rel="noreferrer" className="flex items-center justify-center h-[50px] w-[50px] bg-white rounded-[10px] text-[#FF3E54]"><FaFacebookF className='h-5 w-5' /></a>
                <a href="https://twitter.com/EagleView_Tech/status/1628979356440616960?t=xaPsWT3KMaVtILXKG0Z5bQ&s=19"  target="_blank" rel="noreferrer" className="flex items-center justify-center h-[50px] w-[50px] bg-white rounded-[10px] text-[#FF3E54]"><FaTwitter className='h-5 w-5' /></a>
            </div>
        </div>
        <div className="flex flex-col">
            <div className="font-bold text-2xl">Services</div>
            <RedPill />
            <div className="flex flex-col gap-5">
                {
                    ["Trackers", "Energy Solutions", "Shakti Pumps", "Batteries"].map((it, i)=>(
                        <div className="text-base font-medium capitalize">{it}</div>
                    ))
                }
            </div>
        </div>
        <div className="flex flex-col text-white">
            <div className="font-bold text-2xl">Company</div>
            <RedPill />
            <div className="flex flex-col items-start gap-4">
                <a href="mailto:Info@eagleviewltd.com" target="_blank" className="flex items-center gap-4" rel="noreferrer">
                    <AiFillMail className='h-5 w-5' />
                    <div className="cursor-pointer" >Info@eagleviewltd.com</div>
                </a>
                <div className="flex items-center gap-4">
                    <GoLocation className='h-5 w-5 text-white' />
                    <div className="">Address- 67, Old Abeouka motor road , Agege Lagos</div>
                </div>
                <a href={`https://wa.me/${'+2348021001755'}`} target="_blank" className="flex items-center gap-4" rel="noreferrer">
                    <IoLogoWhatsapp className='h-5 w-5' />
                    <div className="">+2348021001755</div>
                </a>
                <a href="tel:+2348077775059" className="flex items-center gap-4">
                    <AiFillPhone className='h-5 w-5' />
                    <div className="">+2348077775059</div>
                </a>
                <div className="flex items-center gap-4">
                    <BsCalendar2Day className='h-5 w-5' />
                    <div className="">Available Mondays to Sundays</div>
                </div>
                <div className="flex items-center gap-4">
                    <IoMdTimer className='h-5 w-5' />
                    <div className=""> 8am to 5pm</div>
                </div>
            </div>
        </div>
        <div className="flex flex-col">
            <div className="font-bold text-2xl">Subscribe</div>
            <RedPill />
            <div className="font-medium text-xl lg:max-w-[260px] mb-[30px]">
                Be the first to find out about our new products
            </div>
            <input type="email" className='h-[50px] rounded-[10px] pl-6 placeholder-black' placeholder='Email Address' />
            <div className="w-full flex justify-end mt-5">
                <a href="mailto:Info@eagleviewltd.com" className="rounded-[10px] py-2 px-4 bg-[#FF3E54]">
                    Submit
                </a>
            </div>
        </div>
    </div>
    <p className="mt-8 text-base text-center">&copy; {new Date().getFullYear()} Eagle View. All rights reserved.</p>
    </div>
  )
}
