import React from 'react'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaUserTie, FaQuoteRight } from "react-icons/fa"
import Carousel from 'react-material-ui-carousel';

export default function Testimonials() {
  return (
    <div className="flex flex-col items-center gap-10 w-full py-[100px] lg:px-[150px]">
        <div className="text-center  px-6 ">
        <div className="text-[#FF3E54] font-bold text-2xl">\ From our Customers \</div>
            <div className="text-[#0E1F51] font-bold text-5xl text-[40px] mt-[10px] mb-[30px]">Testimonials</div>
        </div>
        <Carousel
    animation="slide"
    interval={7000}
    swipe={true}
    indicatorContainerProps={{
        className: "flex gap-5 justify-center",
      }}
    className='max-w-[750px] w-full rounded-[10px]'
    >
            {
                [
                    {text: "The security system by Eagle View is the real deal", post: "CEO, ATSC", name: "Bode Fashina"},
                    {text: "The IT services rendered by Eagle View Tech IT team is rendered to perfection", post: "Senior Software Dev, Andela", name: "Jubril Issa"},
                    {text: "The security system by Eagle View is the real deal", post: "CEO, ATSC", name: "Bode Fashina"},
                ].map((col)=>{
                    return(
                        <div className="px-[15px] w-full">
                    <div className="rounded-[10px] bg-[#f7f7f7] p-[30px] w-full flex flex-col">
                        <div className="flex items-center w-full justify-between">
                        <div className="rounded-full h-20 w-20 text-[#FFF] bg-[#0E1F51] flex items-center justify-center">
                            <FaUserTie className='h-10 w-10' />
                        </div>
                            <FaQuoteRight className='h-10 w-10 text-[#0E1F51]' />
                        </div>
                        <div className="font-medium text-base pt-10 ">
                        {col.text}.
                        </div>
                        <div className="pt-[30px] flex flex-col gap-[5px]">
                            <div className=" font-bold text-base text-[#ff3e54]">
                            {col.name}
                            </div>
                            <div className=" font-medium text-sm text-[#0e1f51]">
                            {col.post}
                            </div>
                        </div>
                    </div>
                        </div>
                    )})
                }
            </Carousel>

    </div>
  )
}
