import React from 'react'
import PageHeader from '../components/PageHeader'
import HomeAbout from '../components/HomeAbout'
import HomeCTA from '../components/HomeCTA'
import HomeService from '../components/HomeService'
import Portfolio from '../components/Portfolio'

export default function Services() {
  return (
    <div>
        <PageHeader title="Services" page="services" />
        <HomeAbout img={require('../img/homeservice.png')} title={"We Offer IT and Security Services"} section={"What We Do"} details={()=>(
            <>
            An integrated turn key solutions is what we offer you.At <b className="text-[#FF3E54] ">EAGLE VIEW</b>, our experienced and certified team of professionals delivers the most complex IT solutions to your organization with world-class services. And because we have multi-platform skills, we are able to integrate different technologies as your operation expects, thus making us a «one stop shop» for your advanced technology requirements. We do not just supply technology products but we consult, implement, configure, integrate, support and maintain various technology solutions, as well as provide hands-on training to ensure your team is fully capable of managing the infrastructure. Just make the right choice and leave the rest to us.
            </>
        )} />
        <HomeService />
        {/* <Portfolio /> */}
        <HomeCTA />
    </div>
  )
}
