import React from 'react'

export default function PageHeader({title, page}: any) {
  return (
    <div className='py-[100px] flex flex-col items-center justify-center w-full bg-[#0E1F51] gap-[10px]'>
        <div className="text-[#FF3E54] text-[48px] leading-[58px] font-bold">{title}</div>
        <div className="text-2xl leading-[20px] font-medium text-white">Home / {page}</div>
    </div>
  )
}
