import React from 'react'
import HomeContact from '../components/HomeContact'
import HomeCTA from '../components/HomeCTA'

export default function Contact() {
  return (
    <div>
        <HomeContact />
        <HomeCTA />
    </div>
  )
}
