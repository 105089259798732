import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  AiOutlineMenu,
} from 'react-icons/ai'
import {
  TiTimes,
} from 'react-icons/ti'
import { Link, NavLink } from "react-router-dom"

const navigations = [
  {name: "Home", url: "/"},
  {name: "About Us", url: "/about-us"},
  {name: "Services", url: "/services"},
  {name: "Contact Us", url: "/contact-us"},
]

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  return (
    <Popover className="relative inset-0 bg-white">
      {({ open }) => (
        <>
          <div className="shadow flex items-center justify-between px-4 py-3 sm:px-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
                <img
                  className="h-16 sm:h-16"
                  src={require('../img/logo.png')}
                  // src="/img/logo.png"
                  alt=""
                />
            </div>
            <div className="-my-2 -mr-2 md:hidden">
              <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-[#0E1F51] hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                <span className="sr-only">Open menu</span>
                <AiOutlineMenu className="w-6 h-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <nav className="hidden space-x-10 md:flex">
              {
                navigations.map((nav, index)=>(
                  <NavLink className={({isActive}) => `cursor-pointer hover:text-[#FF3E54] font-semibold text-base ${isActive ? "text-[#ff3e54]" : "text-[#0E1F51]"}`} key={index} to={nav.url}>
                {nav.name}
                  </NavLink>
                ))
              }
            </nav>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              static
              className="absolute inset-x-0 top-0 z-40 p-2 transition origin-top-right transform md:hidden"
            >
              <div className="bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
                <div className="px-5 pt-5 pb-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <img
                        className="w-auto h-16"
                        src={require('../img/logo.png')}
                        // src="/img/logo.png"
                        alt="Workflow"
                      />
                      {/* <Link href="/">
                <h1 className="text-2xl font-extrabold cursor-pointer">Eagle View</h1>
                      </Link> */}
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-[#0E1F51] hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                        <span className="sr-only">Close menu</span>
                        <TiTimes className="w-6 h-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="px-5 py-6">
                  <div className="grid grid-cols-1 gap-4">
                    {
                      navigations.map((nav, index)=>(
                    <NavLink className={({isActive}) => `cursor-pointer hover:text-[#FF3E54] font-semibold text-base ${isActive ? "text-[#ff3e54]" : "text-[#0E1F51]"}`} to={nav.url} key={index}>
                      
                      {nav.name}
                    </NavLink>
                      ))
                    }
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
